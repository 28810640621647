<template>
  <NavBar />
  <router-view/>
</template>

<script>
import NavBar from "@/components/Layout/NavBar.vue"

export default {
  components: {
    NavBar
  }
}
</script>

<style>
body {
    margin: 0;
    background-color: #FCFCFC;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #FCFCFC;
}
.text-dark{
    color: #2c3e50;
}

a {
  text-decoration: none;
  font-weight: bold;
  color: #2c3e50;
}
.container {
    margin: 0 1em;
}
</style>
