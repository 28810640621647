<template>
  <div class="form-container p-4">
    <div v-if="!submitted" >
        <h2 class="text-2xl font-bold text-dark">Contact Us!</h2>
        <div class="form-group">
          <label class="text-dark" for="name">Name:</label>
          <input class="text-dark" type="text" id="name" v-model="formData.name">
        </div>
        <div class="form-group">
          <label class="text-dark" for="phone">Phone Number:</label>
          <input class="text-dark" type="text" id="phone" v-model="formData.phone">
        </div>
        <div class="form-group">
          <label class="text-dark" for="email">Email:</label>
          <input class="text-dark" type="email" id="email" v-model="formData.email">
        </div>
        <div class="form-group">
          <input type="submit" value="Submit" @click="submitForm">
        </div>
        <p v-if="error" style="color:red;" >There was an error. Please try again later</p>
        <!-- <p class="error-message">{{ errorMessage }}</p> -->
    </div>
    <div v-if="submitted">
      <p class="text-dark">Thank you for your submission! We are excited to chat with you!</p>
    </div>
  </div>
</template>

<script>
export default {
    name: 'ContactUs',
    data() {
        return {
            formData: {
                name: '',
                email: '',
                phone: '',
            },
            error: false,
            submitted: false
        }
    },
    methods: {
        async submitForm() {
            const response = await fetch('api/v1/contact', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(this.formData)
            });
            if (response.ok) {
                this.submitted = true;
            }
            else {
                this.error = true;
            }
        }
    }
}
</script>

<style scoped>
.form-container {
  max-width: 400px;
  margin: 0 auto;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  padding: 1em;
  background-color: white;
  overflow: hidden;
}

input {
    box-sizing: border-box;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group input[type="submit"] {
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
}

.form-group input[type="submit"]:hover {
  background-color: #45a049;
}

.error-message {
  color: red;
  margin-top: 5px;
  font-size: 14px;
}
</style>
