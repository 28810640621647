<template>
  <div>
    <nav class="flex-container">
      <div>
        <router-link to="/">
          <h2 class="text-2xl font-bold">Structured Safety</h2>
        </router-link>
      </div>
      <div>
<!--        <router-link to="/pricing"> 
          <p>Pricing</p>
        </router-link> -->
      </div>
      <div class="flex-container">
        <router-link class="flex items-center" to="/toolbox-talks">
          <p>Toolbox Talks</p>
        </router-link>
        <router-link class="flex items-center" to="/company/Demo">
          <p>QR Code Demo</p>
        </router-link>
      </div>
    </nav>
  </div>
</template>

<script>
</script>

<style scoped>
h2 {
    margin: .5em .5em;
    color: #FCFCFC;
}

.flex-container{
    display: flex;
    justify-content: space-between;
    background-color: #16425B;
}

nav {
    padding: 0;
    margin: 0;
}
p {
    color: #FCFCFC;
    margin-right: 1em;
    margin-left: 1em;
}
a:hover {
    background-color: #386473;
}
a:active {
    background-color: #22454A;
}
</style>
