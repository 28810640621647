<template>
  <div class="p-4">
      <h1 class="text-4xl font-bold text-dark">Testimonials</h1>
      <div class="carousel">
        <!-- <button class="carousel-button" @click="prevItem">&lt;</button> -->
        <div class="carousel-item text-dark" v-for="(item, index) in items" :key="index" v-show="index === currentIndex">
          <p>"{{ item.content }}"</p>
          <small class="text-dark"> {{item.author}} </small>
        </div>
        <!-- <button class="carousel-button" @click="nextItem">></button> -->
      </div>
  </div>
</template>

<script>
export default {
  name: "TestimonialsSlideshow",
  data() {
    return {
      items: [
        {
            content: "Structured Safety has become an integral part of our award winning program here at Coffman excavation.  The content, innovation and customer support over the past 3 years has helped Coffman excavation become recognized not only AGC of Oregon but nationally placing in the top ten companies in the nation as a leader in construction safety across all trades in the AGC WTW CSEA process.",
            author: "Scott Brawner, EHS Manager - Coffman Excavation"
        },
      ],
      currentIndex: 0,
    };
  },
  methods: {
    prevItem() {
      if (this.currentIndex === 0) {
        this.currentIndex = this.items.length - 1;
      } else {
        this.currentIndex--;
      }
    },
    nextItem() {
      if (this.currentIndex === this.items.length - 1) {
        this.currentIndex = 0;
      } else {
        this.currentIndex++;
      }
    },
  },
};
</script>

<style scoped>
.carousel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.carousel button {
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  background-color: #f0f0f0;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
}

.carousel-item {
  width: 100%;
  text-align: center;
  display: none;
}

.carousel-item:first-of-type {
  display: block;
}

.carousel-button {
  background-color: #ffffff;
  color: #333333;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.carousel-button:hover {
  background-color: #333333;
  color: #ffffff;
}

.carousel-button:active {
  background-color: #000000;
  color: #ffffff;
}

p {
    text-align: justify;
    text-align-last: center;
    text-justify: inner-word;
    display: block;
    width: 80%;
    margin: auto;

}
</style>
