<template>
  <div class="home">
    <div id="hero-banner">
      <div>
        <h1 class="pt-4 text-4xl font-bold">Safety: Digitized, Available, and Current</h1>
        <p class="hero-text">
          Join our platform now to easily store and access all your safety documents and receive weekly toolbox talk 
          materials. Don't have policies yet? We can help with that! Keep your employees safe and your 
          business compliant with just a few clicks. Sign up today!
        </p>
        <ContactUs />
      </div>
    </div>
    <div class="notable-customers p-4">
      <h1 class="text-4xl font-bold text-dark">Used By</h1>
      <div class="customer-flex">
        <img class="wider" :src="require('@/assets/images/coffman-excavation.webp')" alt="Coffman Excavation" />
        <img class="narrower" :src="require('@/assets/images/brix-logo.png')" alt="Brix Paving Northwest, INC." />
      </div>
    </div>
    <div class="copy">
      <h2 class="text-4xl font-bold">Feature Set</h2>
      <ul class="feature-set">
        <li class="feature">
            Easy Document Storage - Keep all your safety policies, forms, and other documents in one 
            accessible, online location.
        </li>
        <li class="feature">
            QR Code Access - Easily access your safety documents using a simple QR code, saving you time and hassle. 
            Update your documents once, and your whole company can see it!
        </li>
        <li class="feature">
            Weekly Toolbox Talks - Receive weekly safety talks to share with your employees and keep 
            them informed and up-to-date. Our toolbox talks are written and reviewed every week by safety professionals so we can respond to changing 
            hazards in the construction environment. Optionally, you can recieve the toolbox talking as
            PDF downloads in English and Spanish!
        </li>
        <li class="feature">
            OSHA Compliant Policies - Aquire a library of OSHA compliant safety policies to ensure your workplace 
            is always compliant. Your letterhead, kept up to date automatically. Or, feel free to bring your own!
        </li>
      </ul>
    </div>
    <div>
      <TestimonialsSlideshow />
    </div>
    <div class="flex justify-around flex-wrap text-dark samples">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title font-bold">QR Code</h5>
          <p class="card-text">View a sample QR Code</p>
          <router-link to="/company/Demo" class="btn btn-primary">Learn More</router-link>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title font-bold">Toolbox Talks</h5>
          <p class="card-text">Here is this weeks Toolbox talk!</p>
          <router-link to="/toolbox-talks" class="btn btn-primary">
            Vew Toolbox Talk
          </router-link>
        </div>
      </div>
    </div>
    <div class="overflow-hidden bg-dark p-4">
      <h2 class="text-2xl font-bold">Contact us now to simplify your safety program and stay up-to-date with the latest safety requirements.</h2>
      <div class="contact-form">
        <ContactUs />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContactUs from "@/components/Forms/ContactUs"
import TestimonialsSlideshow from "@/components/HomeView/TestimonialsSlideshow.vue"

export default {
  name: 'HomeView',
  components: {
    TestimonialsSlideshow,
    ContactUs
  },
}
</script>

<style scoped>
.contact-form {
    width: 100%;
    margin-bottom: 2em;
}

.samples {
    padding: 3em 0;
    background-color: #2F6690;
}

.flex-around {
    display: flex;
    justify-content: space-around;
}

.overflow-hidden {
    overflow: hidden;
}

.wider {
    flex-shrink: 0;
}

.narrower {
    max-height: 5em;
    flex-shrink: 1;
}
#hero-banner {
    display: flex;
    align-items: center;
    overflow: hidden;
    min-height: 50vh;
    background-color: #2F6690;
}

.hero-text {
    margin-bottom: 1em;
}

.customer-flex {
    display: flex;
    align-item: center;
    justify-content: space-around;
    max-height: 10em;
}
p {
    margin: 0 3em;
}
.copy {
    overflow: hidden;
    padding: 3em 0;
    background-color: #2F6690;
}
.feature-set {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feature {
  margin: 20px 0;
  padding: 20px;
  max-width: 600px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 0 0;
  font-size: 16px;
  line-height: 1.5;

}
.bg-dark {
    background-color: #16425B;
}
.card {
  width: 18rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  background-color: white;
}

.card-body {
  padding: 1rem;
}

.card-title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.card-text {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.btn {
  background-color: #007bff;
  color: #fff;
  padding: .5em;
  border-radius: 10px;
}

.btn:hover {
  background-color: #0069d9;
  color: #fff;
}

.btn:focus,
.btn:active {
  box-shadow: none;
  background-color: #0062cc;
  color: #fff;
}
</style>
