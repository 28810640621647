import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
//   {
//     path: '/pricing',
//     name: 'pricing',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/PricingView.vue')
//   },
  {
    path: '/contact',
    name: 'contact us',
    component: () => import ('../views/ContactUsView.vue')
  },
  {
    path: '/company/:companyId',
    name: 'company view',
    beforeEnter: async (to, from, next) => {
      // check if companyId is actually a company name
      const companyId = to.params.companyId;
      const url = `/api/v1/customers/${companyId}/id-for-name`;

      let redirectLocation = "";
      try {
          const data = await fetch(url);
          const jsonData = await data.json();
          if (data.status === 404 || jsonData === undefined) {
            redirectLocation = "/company-not-found";
          }
          else {
            if (jsonData.isLegacyUrl) {
               redirectLocation = `/company/${jsonData.customerId}/qr-code/${jsonData.defaultQrCode}`;
            }
            //else, in the future there should be a dashboard here.
          }
      }
      catch (error)
      {
        redirectLocation = "/company-not-found";
      }
          next(redirectLocation);
    }
  },
  {
    path: '/qr-codes/:qrCodeId',
    name: 'company from pr code',
    beforeEnter: async (to, from, next) => {
      const qrCodeId = to.params.qrCodeId;
      const url = `/api/v1/qr-codes/${qrCodeId}`;

      const data = await fetch(url);
      const jsonData = await data.json();
      let redirectLocation = "";
      if(data.status === 404 || jsonData === undefined) {
        redirectLocation = "/company-not-found";
      }
      else if (jsonData.qrCodeType === "FB") {
        redirectLocation = `/company/${jsonData.customerId}/qr-code/${qrCodeId}${(jsonData.prefix && jsonData.prefix.length > 0) ? jsonData.prefix : ""}`
      }
      else {
        redirectLocation("/")
      }
      next(redirectLocation);
    }
  },
  {
    path: '/company/:companyId/qr-code',
    name: 'default file browser',
    component: () => import('@/views/CompanyView.vue'),
    props: true
  },
  {
    path: '/company/:companyId/qr-code/:qrCodeId/:prefix*',
    name: 'file browser',
    component: () => import('@/views/CompanyView.vue'),
    props: true
  },
  {
    path: '/toolbox-talks',
    name: 'Toolbox Talks',
    component: () => import ('@/views/ToolboxTalkView.vue')
  },

  // Custom routes to support legacy QR codes
  {
    path: '/frahler-electric-company-pvjaxudmry',
    redirect: '/company/frahler-electric-company-pvjaxudmry'
  },
  {
    path: '/mccoy-electric-qr-code-uvhu9jk1ia',
    redirect: '/company/mccoy-electric-qr-code-uvhu9jk1ia',
  },
  {
    path: '/company-not-found',
    name: 'company not found',
    component: () => import('@/views/error/CompanyNotFound.vue')
  }
]

const router = createRouter({
  mode: 'history',
  scrollBehavior() {
    return { top: 0 };
  },
  history: createWebHistory(),
  routes
})

export default router
